import { Controller } from "@hotwired/stimulus"
import { addSource } from "@concerto/ticket_source_tracking"

export default class extends Controller {
  static values = {
    concertIds: Array,
    sourceTitoCode: String
  }

  initialize() {
    this.concertIdsReady = false
    this.sourceTitoCodeReady = false
    this.codeSaved = false
  }

  concertIdsValueChanged(current) {
    if (current) {
      this.concertIdsReady = true
    }

    this.saveSourceToLocalStorageIfNotYetSaved()
  }

  sourceTitoCodeValueChanged(current) {
    if (current) {
      this.sourceTitoCodeReady = true
    }

    this.saveSourceToLocalStorageIfNotYetSaved()
  }

  saveSourceToLocalStorageIfNotYetSaved() {
    const ready = this.concertIdsReady && this.sourceTitoCodeReady

    if (!this.codeSaved && ready) {
      this.concertIdsValue.forEach((concertID) => {
        addSource({
          newSource: this.sourceTitoCodeValue,
          concertID
        })
      })

      this.codeSaved = true
    }
  }
}